<template>
  <div class="login login-4 login-signin-on d-flex flex-row-fluid" id="kt_login">
    <div
      class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
      style="background-image: url('assets/media/bg/bg-3.jpg')"
    >
      <div class="login-form text-center p-7 position-relative overflow-hidden">
        <!--begin::Login Header-->
        <div class="d-flex flex-center mb-15">
          <a href="#">
            <Logo class="max-h-150px" />
          </a>
        </div>
        <!--end::Login Header-->
        <!--begin::Login Sign in form-->

        <div class="login-signin">
          <div class="mb-20">
            <h3 class="display-3">СМС Код</h3>
            <div class="text-muted font-weight-bold">Смс код был отправлен вам на телефон:</div>
          </div>
          <div class="alert alert-danger" v-if="code_error" role="alert">Смс код не верен</div>
          <form class="form" id="kt_login_signin_form" @submit.prevent="submit">
            <div class="form-group mb-5">
              <input
                class="form-control h-auto form-control-solid py-4 px-8"
                type="text"
                placeholder="Смс код"
                name="username"
                v-model="code"
                autocomplete="off"
              />
            </div>
            <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
              <router-link :to="{ name: 'login' }" class="text-muted text-hover-primary"
                >Авторизация</router-link
              >
            </div>
            <button class="btn btn-success font-weight-bold px-9 py-4 my-3 mx-4">
              Проверить код
            </button>
          </form>
          <div class="mt-10">
            <span class="opacity-70 mr-4">Еще нет аккаунта?</span>
            <router-link
              :to="{ name: 'registration' }"
              class="text-muted text-hover-primary font-weight-bold"
            >
              Регистрация
            </router-link>
          </div>
        </div>
        <!--end::Login Sign in form-->
      </div>
    </div>
  </div>
</template>

<script>
// import api from "@/core/api";
import { auth } from "@/core/services/auth";
import { getUrl, isUrlExists, removeUrl } from "@/core/utils/returnUrlController";
import api from "@/core/api";
import Logo from "@/assets/icons/logo.svg";

export default {
  components: { Logo },
  data() {
    return {
      code: null,
      code_error: false,
    };
  },
  methods: {
    redirectToBack: function (id) {
      const returnUrl = getUrl(id);
      removeUrl();
      window.open(returnUrl, "_self");
    },

    getToken: function (id) {
      this.code_error = false;
      api
        .getToken(id)
        .then((resp) => {
          auth.setToken(resp.data.access_token);
          this.$router.push({ name: "profile" });
        })
        .catch(() => {
          this.code_error = true;
        });
    },
    submit() {
      const code = `${this.code}`;
      console.log(code);
      try {
        if (isUrlExists()) {
          this.redirectToBack(code);
        } else {
          this.getToken(code);
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./Sms.scss"></style>
